import '../App.css';
import video from '../video/video17.mp4';

function Block17(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog17")}</h2>
                <p style={{ marginTop: '5px' }}>{props.t("blog17-1")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog17-2")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog17-3")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog17-4")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog17-5")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog17-6")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog17-7")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog17-8")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog17-9")}</p>
            </div>
        </div>

    )
}
export default Block17;