import '../App.css';
import video from '../video/video2.mp4';

function Block2(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog2-1")}</h2>
                <p>{props.t("blog1-2")}</p>
                <p style={{ marginTop: '20px', marginBottom: '10px' }}>{props.t("blog2-3")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog2-4")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog2-5")}</p>
                <p style={{ marginTop: '20px', marginBottom: '20px' }}>{props.t("blog2-6")}</p>
                <p style={{ marginBottom: '10px', fontWeight: '800' }}>{props.t("blog2-7")}</p>
            </div>
        </div>

    )
}
export default Block2;