import '../App.css';


function Block1(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                
                <h2 style={{marginTop: '50px',marginBottom: '30px'}}>{props.t("blog1-1")}</h2>
                <p>{props.t("blog1-2")}</p>
                <p style={{marginTop: '20px',marginBottom: '10px'}}>{props.t("blog1-3")}</p>
                <p style={{marginTop: '5px'}}>{props.t("blog1-4")}</p>
                <p style={{marginTop: '5px'}}>{props.t("blog1-5")}</p>
                <p style={{marginTop: '20px',marginBottom: '20px'}}>{props.t("blog1-6")}</p>
                <p style={{marginBottom: '10px', fontWeight: '800'}}>{props.t("blog1-7")}</p>
                <p style={{marginTop: '5px'}}>{props.t("blog1-8")}</p>
                <p style={{marginTop: '5px'}}>{props.t("blog1-9")}</p>
                <p style={{marginTop: '5px'}}>{props.t("blog1-10")}</p>
                <h2 style={{marginTop: '30px', marginBottom: '20px', fontWeight: '800'}} >{props.t("blog1-11")}</h2>
                <p style={{marginTop: '20px',marginBottom: '20px'}}>{props.t("blog1-13")}</p>
                <p style={{marginTop: '20px',marginBottom: '20px'}}>{props.t("blog1-14")}</p>
                <p style={{marginTop: '20px',marginBottom: '20px'}}>{props.t("blog1-15")}</p>
                <p style={{marginTop: '20px',marginBottom: '20px'}}>{props.t("blog1-16")}</p>
            </div>
        </div>

    )
}
export default Block1;