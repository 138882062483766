import '../App.css';
import video from '../video/video14.mp4';

function Block14(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog14")}</h2>
                <p style={{ marginTop: '5px' }}>{props.t("blog14-1")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog14-2")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog14-3")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog14-4")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog14-5")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog14-6")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog14-7")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog14-8")}</p>
            </div>
        </div>

    )
}
export default Block14;