import '../App.css';
import video from '../video/video15.mp4';

function Block15(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog15-1")}</h2>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-1")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-2")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-3")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-4")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-5")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-6")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-7")}</p>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog15-8")}</h2>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-9")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-10")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-11")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-12")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-13")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-14")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-15")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-16")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog15-17")}</p>
            </div>
        </div>

    )
}
export default Block15;