import '../App.css';
import video from '../video/video9.mp4';

function Block9(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog9-8")}</h2>
                <p style={{ marginTop: '5px' }}>{props.t("blog8-1")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog8-2")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog8-3")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog8-4")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog8-5")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog8-6")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog8-7")}</p>
            </div>
        </div>

    )
}
export default Block9;