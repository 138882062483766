import '../App.css';
import video from '../video/video11.mp4';

function Block11(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog11")}</h2>
                <p style={{ marginTop: '5px' }}>{props.t("blog11-1")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog11-2")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog11-3")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog11-4")}</p>
            </div>
        </div>

    )
}
export default Block11;