import '../App.css'
import { Link } from 'react-router-dom'
import { Menu } from 'burger-menu'
import dark from '../img/dark.svg'
import light from '../img/light.svg'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import logo from '../img/main_logo.svg'
import Block1 from '../components/Block1'
import Block2 from '../components/Block2'
import Block3 from '../components/Block3'
import Block4 from '../components/Block4'
import Block5 from '../components/Block5'
import Block6 from '../components/Block6'
import Block7 from '../components/Block7'
import Block8 from '../components/Block8'
import Block9 from '../components/Block9'
import Block10 from '../components/Block10'
import Block11 from '../components/Block11'
import Block12 from '../components/Block12'
import Block13 from '../components/Block13'
import Block14 from '../components/Block14'
import Block15 from '../components/Block15'
import Block16 from '../components/Block16'
import Block17 from '../components/Block17'
import Block18 from '../components/Block18'
import Block19 from '../components/Block19'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'


function Dashboard (props) {
  const location = useLocation()
  const [selectedComponent, setSelectedComponent] = useState(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const component = searchParams.get('component')
    setSelectedComponent(component)
  }, [location.search])

  useEffect(() => {
    Cookies.set('scroll', 1)
  })

  const { t, i18n } = useTranslation()

  const changeLanguage = language => {
    i18n.changeLanguage(language)
  }

  const [isDarkMode, setIsDarkMode] = useState(true)

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode)
  }
  const [isOpen, setIsOpen] = useState(false)

  const [stickyClass, setStickyClass] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar)
    return () => window.removeEventListener('scroll', stickNavbar)
  }, [])

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY
      windowHeight > 800 ? setStickyClass('sticky-nav') : setStickyClass('')
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate)
    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  const handlePopstate = () => {
    const sectionId = 'blog'
    const section = document.getElementById(sectionId)

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }
  const goToSection = () => {
    const sectionId = 'blog'
    const url = `#${sectionId}`
    window.history.pushState({}, '', url)

    const section = document.getElementById(sectionId)

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  function back () {
    const parentElement = window.parent.document.getElementById('blog')
    if (parentElement) {
      parentElement.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return (
    <section className={isDarkMode ? 'light' : 'dark'}>
      <section id='main' className='main_dashboard'>
        <div className={`nav_menu ${stickyClass}`}>
          <div className='container'>
            <Menu
              className='burger-menu'
              isOpen={isOpen}
              selectedKey={'entry'}
              onClose={() => setIsOpen(false)}
            >
              <div className='nav_body nav_body_mob'>
                <div className='nav_links nav_links_mob'>
                  <Link to='/'>
                    <a
                      onClick={e => setIsOpen(false)}
                      className='nav_link'
                      href='#main'
                    >
                      {t('nav1')}
                    </a>
                  </Link>
                  <Link to='/'>
                    <a
                      onClick={e => setIsOpen(false)}
                      className='nav_link'
                      href='#functional'
                    >
                      {t('nav3')}
                    </a>
                  </Link>
                  <Link to='/'>
                    <a
                      onClick={e => setIsOpen(false)}
                      className='nav_link'
                      href='#price'
                    >
                      {t('nav4')}
                    </a>
                  </Link>
                  <Link to='/'>
                    <a
                      onClick={e => setIsOpen(false)}
                      className='nav_link'
                      href='#about'
                    >
                      {t('nav2')}
                    </a>
                  </Link>
                  <a
                    onClick={e => setIsOpen(false)}
                    className='nav_link'
                    rel='noreferrer'
                    target='_blank'
                    href='https://t.me/Expert_bot_chat'
                  >
                    {t('nav5')}
                  </a>
                  <Link to='/'>
                    <a
                      onClick={e => setIsOpen(false)}
                      className='nav_link'
                      href='#contact'
                    >
                      {t('nav6')}
                    </a>
                  </Link>
                </div>
                <div className='nav_buttons nav_buttons_mob'>
                  <div className='switch_language'>
                    <p
                      onClick={() => {
                        setIsOpen(false)
                        changeLanguage('ru')
                      }}
                    >
                      RU
                    </p>
                    <span>/</span>
                    <p
                      onClick={() => {
                        setIsOpen(false)
                        changeLanguage('en')
                      }}
                    >
                      EN
                    </p>
                    <span>/</span>
                    <p
                      onClick={() => {
                        setIsOpen(false)
                        changeLanguage('ua')
                      }}
                    >
                      UA
                    </p>
                  </div>
                </div>
              </div>
            </Menu>
            <div className='nav_body'>
              <img className='nav_logo' src={logo} alt='' />
              <a
                className='open_bot_mob'
                rel='noreferrer'
                target='_blank'
                href='https://t.me/Expert_ProMaker_bot'
              >
                <button className='open_bot'>Expert Bot</button>
              </a>
              <div className='nav_links nav_links_desctop'>
                <Link to='/'>
                  <a className='nav_link' href='#main'>
                    {t('nav1')}
                  </a>
                </Link>
                <Link to='/'>
                  <a className='nav_link' href='#functional'>
                    {t('nav3')}
                  </a>
                </Link>
                <Link to='/'>
                  <a className='nav_link' href='#price'>
                    {t('nav4')}
                  </a>
                </Link>
                <Link to='/'>
                  <a className='nav_link' href='#about'>
                    {t('nav2')}
                  </a>
                </Link>
                <a
                  className='nav_link'
                  rel='noreferrer'
                  target='_blank'
                  href='https://t.me/Expert_bot_chat'
                >
                  {t('nav5')}
                </a>
                <Link to='/'>
                  <a className='nav_link' href='#contact'>
                    {t('nav6')}
                  </a>
                </Link>
              </div>
              <div className='nav_buttons nav_buttons_desctop'>
                <label class='form-switch'>
                  <input
                    className='switch_theme'
                    onClick={toggleTheme}
                    type='checkbox'
                  />
                  <i>
                    <img className='switch_dark' src={dark} alt='' />
                    <img className='switch_light' src={light} alt='' />
                  </i>
                </label>
                <div className='switch_language'>
                  <p onClick={() => changeLanguage('ru')}>RU</p>
                  <span>/</span>
                  <p onClick={() => changeLanguage('en')}>EN</p>
                  <span>/</span>
                  <p
                    onClick={() => {
                      setIsOpen(false)
                      changeLanguage('ua')
                    }}
                  >
                    UA
                  </p>
                </div>
                <a
                  rel='noreferrer'
                  target='_blank'
                  href='https://t.me/Expert_ProMaker_bot'
                >
                  <button className='open_bot'>Expert Bot</button>
                </a>
              </div>
              <div className='mobile-burger'>
                <label class='form-switch'>
                  <input
                    className='switch_theme'
                    onClick={toggleTheme}
                    type='checkbox'
                  />
                  <i>
                    <img className='switch_dark' src={dark} alt='' />
                    <img className='switch_light' src={light} alt='' />
                  </i>
                </label>
                <div
                  className='burger-menu_switch'
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div className='burger-menu_svg'>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <Link to='/' onClick={() => back()}>
            {' '}
            <button className='back'>{t('dashboard_btn')}</button>
          </Link>
          {selectedComponent === 'block1' && <Block1 t={t} />}
          {selectedComponent === 'block2' && <Block2 t={t} />}
          {selectedComponent === 'block3' && <Block3 t={t} />}
          {selectedComponent === 'block4' && <Block4 t={t} />}
          {selectedComponent === 'block5' && <Block5 t={t} />}
          {selectedComponent === 'block6' && <Block6 t={t} />}
          {selectedComponent === 'block7' && <Block7 t={t} />}
          {selectedComponent === 'block8' && <Block8 t={t} />}
          {selectedComponent === 'block9' && <Block9 t={t} />}
          {selectedComponent === 'block10' && <Block10 t={t} />}
          {selectedComponent === 'block11' && <Block11 t={t} />}
          {selectedComponent === 'block12' && <Block12 t={t} />}
          {selectedComponent === 'block13' && <Block13 t={t} />}
          {selectedComponent === 'block14' && <Block14 t={t} />}
          {selectedComponent === 'block15' && <Block15 t={t} />}
          {selectedComponent === 'block16' && <Block16 t={t} />}
          {selectedComponent === 'block17' && <Block17 t={t} />}
          {selectedComponent === 'block18' && <Block18 t={t} />}
          {selectedComponent === 'block19' && <Block19 t={t} />}
        </div>
      </section>
    </section>
  )
}
export default Dashboard
