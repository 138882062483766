import '../App.css';
import video from '../video/video5.mp4';

function Block5(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog5")}</h2>
                <p style={{ marginTop: '20px', marginBottom: '20px' }}>{props.t("blog5-1")}</p>
                <p style={{ marginTop: '20px', marginBottom: '20px' }}>{props.t("blog5-2")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog5-3")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog5-4")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog5-5")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog5-6")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog5-7")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog5-8")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog5-9")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog5-10")}</p>
            </div>
        </div>

    )
}
export default Block5;