import '../App.css';
import video from '../video/video3.mp4';

function Block3(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog3")}</h2>
                <p style={{ marginTop: '5px' }}>{props.t("blog3-1")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog3-2")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog3-3")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog3-4")}</p>
                <p style={{ marginTop: '20px', marginBottom: '20px' }}>{props.t("blog3-5")}</p>
            </div>
        </div>

    )
}
export default Block3;