import '../App.css';
import video from '../video/video6.mp4';

function Block6(props) {

    return (
        <div className='active_block'>

            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog6-1")}</h2>
                <p style={{ marginTop: '20px', marginBottom: '20px', fontWeight: '800' }}>{props.t("blog6-11")}</p>
                <p style={{ marginTop: '20px', marginBottom: '20px' }}>{props.t("blog6-2")}</p>
                <p style={{ marginTop: '20px', marginBottom: '20px' }}>{props.t("blog6-3")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog6-4")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog6-5")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog6-6")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog6-7")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog6-8")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog6-9")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog6-10")}</p>
            </div>
        </div>

    )
}
export default Block6;