import '../App.css';
import video from '../video/video7.mp4';

function Block7(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog7-1")}</h2>
                <p style={{ marginTop: '20px', marginBottom: '20px', fontWeight: '800' }}>{props.t("blog7-2")}</p>
                <p style={{ marginTop: '20px', marginBottom: '20px' }}>{props.t("blog7-3")}</p>
                <p style={{ marginTop: '20px', marginBottom: '20px' }}>{props.t("blog7-4")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog7-5")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog7-6")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog7-7")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog7-8")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog7-9")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog7-10")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog7-11")}</p>
            </div>
        </div>

    )
}
export default Block7;