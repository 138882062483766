import React, { useState, useEffect } from 'react'
import '../App.css'
import logo from '../img/main_logo.svg'
import contactLogo from '../img/contact.svg'
import mainPhoto from '../img/main_photo.png'
import dark from '../img/dark.svg'
import light from '../img/light.svg'
import priceDone from '../img/price_done.svg'
import aboutLogo from '../img/about_logo.png'
import aboutDone from '../img/hands.svg'
import aboutUser from '../img/about_air.svg'
import aboutMessage from '../img/about_like.svg'
import blogOpen from '../img/blog_open.svg'
import { useTranslation } from 'react-i18next'
import { Menu } from 'burger-menu'
import { Link } from 'react-router-dom'
import 'burger-menu/lib/index.css'
/*import TelegramWidget from '../page/TelegramWidget'*/
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import response1 from '../img/response1.jpg'
import response2 from '../img/response2.jpg'
import response3 from '../img/response3.jpg'
import response4 from '../img/response4.jpg'
import response5 from '../img/response5.jpg'
import Cookies from 'js-cookie'

function Main (props) {
  /* CONTACT VALUE */
  const [inputName, setInputName] = useState('')
  const [inputTelegram, setInputTelegram] = useState('')
  const [inputEmail, setInputEmail] = useState('')
  const [inputPhone, setInputPhone] = useState('')
  /* CONTACT VALUE */

  const [isOpen, setIsOpen] = useState(false)

  const { t, i18n } = useTranslation()

  const changeLanguage = language => {
    i18n.changeLanguage(language)
  }
  const [isDarkMode, setIsDarkMode] = useState(true)

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode)
  }

  useEffect(() => {
    const cookieData = Cookies.get('scroll')
    console.log(`key: get, data: ${cookieData}`)
    if (cookieData == 1) {
      const parentElement = window.document.getElementById('blogId')
      if (parentElement) {
        parentElement.scrollIntoView({ behavior: 'smooth' })
        Cookies.set('scroll', 0)
      }
    }
  }, [])

  const anchors = document.querySelectorAll('a[href*="#"]')

  for (let anchor of anchors) {
    anchor.addEventListener('click', function (e) {
      e.preventDefault()

      const blockID = anchor.getAttribute('href').substr(1)

      document.getElementById(blockID).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    })
  }

  return (
    <section className={isDarkMode ? 'light' : 'dark'}>
      <section id='main' className='main'>
        <div className={`nav_menu`}>
          <div className='container'>
            <Menu
              className='burger-menu'
              isOpen={isOpen}
              selectedKey={'entry'}
              onClose={() => setIsOpen(false)}
            >
              <div className='nav_body nav_body_mob'>
                <div className='nav_links nav_links_mob'>
                  <a
                    onClick={e => setIsOpen(false)}
                    className='nav_link'
                    href='#main'
                  >
                    {t('nav1')}
                  </a>
                  <a
                    onClick={e => setIsOpen(false)}
                    className='nav_link'
                    href='#about'
                  >
                    {t('nav2')}
                  </a>
                  <a
                    onClick={e => setIsOpen(false)}
                    className='nav_link'
                    href='#functional'
                  >
                    {t('nav3')}
                  </a>
                  <a
                    onClick={e => setIsOpen(false)}
                    className='nav_link'
                    href='#price'
                  >
                    {t('nav4')}
                  </a>

                  <a
                    onClick={e => setIsOpen(false)}
                    className='nav_link'
                    rel='noreferrer'
                    target='_blank'
                    href='https://t.me/Expert_bot_chat'
                  >
                    {t('nav5')}
                  </a>
                  <a
                    onClick={e => setIsOpen(false)}
                    className='nav_link'
                    href='#contact'
                  >
                    {t('nav6')}
                  </a>
                  <a
                    onClick={e => setIsOpen(false)}
                    className='nav_link'
                    href='#blog'
                  >
                    {t('nav7')}
                  </a>
                  <a
                    onClick={e => setIsOpen(false)}
                    className='nav_link'
                    href='#response'
                  >
                    {t('nav8')}
                  </a>
                </div>
                <div className='nav_buttons nav_buttons_mob'>
                  <div className='switch_language'>
                    <p
                      onClick={() => {
                        setIsOpen(false)
                        changeLanguage('ru')
                      }}
                    >
                      RU
                    </p>
                    <span>/</span>
                    <p
                      onClick={() => {
                        setIsOpen(false)
                        changeLanguage('en')
                      }}
                    >
                      EN
                    </p>
                    <span>/</span>
                    <p
                      onClick={() => {
                        setIsOpen(false)
                        changeLanguage('ua')
                      }}
                    >
                      UA
                    </p>
                  </div>
                </div>
              </div>
            </Menu>
            <div className='nav_body'>
              <img className='nav_logo' src={logo} alt='' />
              <a
                className='open_bot_mob'
                rel='noreferrer'
                target='_blank'
                href='https://t.me/Expert_ProMaker_bot'
              >
                <button className='open_bot'>Expert Bot</button>
              </a>
              <div className='nav_links nav_links_desctop'>
                <a className='nav_link' href='#main'>
                  {t('nav1')}
                </a>
                <a className='nav_link' href='#about'>
                  {t('nav2')}
                </a>
                <a className='nav_link' href='#functional'>
                  {t('nav3')}
                </a>
                <a className='nav_link' href='#price'>
                  {t('nav4')}
                </a>
                <a
                  className='nav_link'
                  rel='noreferrer'
                  target='_blank'
                  href='https://t.me/Expert_bot_chat'
                >
                  {t('nav5')}
                </a>
                <a className='nav_link' href='#blog'>
                  {t('nav7')}
                </a>
                <a className='nav_link' href='#response'>
                  {t('nav8')}
                </a>
              </div>
              <div className='nav_buttons nav_buttons_desctop'>
                <label class='form-switch'>
                  <input
                    className='switch_theme'
                    onClick={toggleTheme}
                    type='checkbox'
                  />
                  <i>
                    <img className='switch_dark' src={dark} alt='' />
                    <img className='switch_light' src={light} alt='' />
                  </i>
                </label>
                <div className='switch_language'>
                  <p onClick={() => changeLanguage('ru')}>RU</p>
                  <span>/</span>
                  <p onClick={() => changeLanguage('en')}>EN</p>
                  <span>/</span>
                  <p
                    onClick={() => {
                      setIsOpen(false)
                      changeLanguage('ua')
                    }}
                  >
                    UA
                  </p>
                </div>
                <a
                  rel='noreferrer'
                  target='_blank'
                  href='https://t.me/Expert_ProMaker_bot'
                >
                  <button className='open_bot'>Expert Bot</button>
                </a>
              </div>
              <div className='mobile-burger'>
                <label class='form-switch'>
                  <input
                    className='switch_theme'
                    onClick={toggleTheme}
                    type='checkbox'
                  />
                  <i>
                    <img className='switch_dark' src={dark} alt='' />
                    <img className='switch_light' src={light} alt='' />
                  </i>
                </label>
                <div
                  className='burger-menu_switch'
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div className='burger-menu_svg'>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='header_figure'></div>
          <div className='header_body'>
            <img src={mainPhoto} alt='' />
            <div className='header_body_content'>
              <h1>Expert ProMaker bot</h1>
              <h2>{t('mainTitle')}</h2>
              <h2>{t('mainSubTitle1')}</h2>
              <h2>{t('mainSubTitle2')}</h2>
              <div className='header_body_buttons'>
                <a
                  rel='noreferrer'
                  target='_blank'
                  href='https://t.me/Expert_ProMaker_bot'
                >
                  <button className='body_buttons_left'>{t('mainBtn1')}</button>
                </a>
                <a className='nav_link' href='#price'>
                  <button className='body_buttons_right'>
                    {t('mainBtn2')}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='about' className='about'>
        <div className='container'>
          <div className='about_top'>
            <div className='about_textarea'>
              <h2>{t('about')}</h2>
              <p>{t('about_title')}</p>
            </div>
            <img src={aboutLogo} alt='' />
          </div>
          <div className='about_bottom'>
            <div className='about_img_list'>
              <div className='about_img_block'>
                <img src={aboutUser} alt='' />
                <p>{t('about_desc_logo1')}</p>
              </div>
              <div className='about_img_line'>
                <div></div>
                <div></div>
              </div>
              <div className='about_img_block'>
                <img src={aboutMessage} alt='' />
                <p>{t('about_desc_logo2')}</p>
              </div>

              <div className='about_img_line'>
                <div></div>
                <div></div>
              </div>
              <div className='about_img_block'>
                <img src={aboutDone} alt='' />
                <p>{t('about_desc_logo3')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='functional' className='features'>
        <h2 className='features_plot'>{t('functional')}</h2>
        <div className='container'>
          <div className='features_body'>
            <div className='features_info_left'>
              <div className='features_info_block'>
                <div className='info_block_left'>
                  <h2>{t('functional_title1')}</h2>
                  <p className='info_style_left'>{t('functional_subtitle1')}</p>
                  <p className='info_style_left'>{t('functional_subtitle2')}</p>
                  <p className='info_style_left'>{t('functional_subtitle3')}</p>
                  <p className='info_style_left'>{t('functional_subtitle4')}</p>
                  <p className='info_style_left'>{t('functional_subtitle5')}</p>
                  <p className='info_style_left'>{t('functional_subtitle6')}</p>
                  <p className='info_style_left'>{t('functional_subtitle7')}</p>
                  <p className='info_style_left'>{t('functional_subtitle8')}</p>
                  <p className='info_style_left'>{t('functional_subtitle9')}</p>
                  <p className='info_style_left'>
                    {t('functional_subtitle10')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle11')}
                  </p>
                </div>
              </div>
            </div>
            <img src={mainPhoto} alt='' />
            <div className='features_info_right'>
              <div className='features_info_block'>
                <div className='info_block_right'>
                  <h2>{t('functional_title3')}</h2>

                  <p className='info_style_left'>
                    {t('functional_subtitle20')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle21')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle22')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle23')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle24')}
                  </p>
                </div>
                <div style={{ marginTop: '40px' }} className='info_block_right'>
                  <h2>{t('functional_title2')}</h2>
                  <p className='info_style_left'>
                    {t('functional_subtitle12')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle13')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle11')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle14')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle15')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle16')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle17')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle18')}
                  </p>
                  <p className='info_style_left'>
                    {t('functional_subtitle25')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='features_body_bottom'>
          <li>{t('features_body_bottom1')}</li>
          <li>{t('features_body_bottom2')}</li>
          <li>{t('features_body_bottom3')}</li>
          <li>{t('features_body_bottom4')}</li>
          <li>{t('features_body_bottom7')}</li>
          <li>{t('features_body_bottom5')}</li>
          <li>{t('features_body_bottom6')}</li>
        </div>
      </section>
      <section id='price' className='price'>
        <div className='price_figure_left'></div>
        <div className='price_figure_right'></div>
        <h2 className='price_plot'>{t('price_title')}</h2>
        <div className='container'>
          <div className='price_list'>
            <div className='price_block'>
              <div className='price_circle'></div>
              <h2>{t('price_block_title1')}</h2>
              <div className='price_block_body'>
                <h3>{t('price_block_subtitle1')}</h3>
                <p>
                  <img src={priceDone} alt='' />
                  {t('1_price_block_desc1')}
                </p>
                <h3>{t('price_block_subtitle2')}</h3>
                <p>
                  <img src={priceDone} alt='' />
                  {t('1_price_block_desc2')}
                </p>
              </div>
              <div className='price_block_price'>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>6</span>/
                  {t('price_mounth1')}
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>15</span>/3{' '}
                  {t('price_mounth2')} ( 1 {t('price_mounth1')} = 5$)
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>24</span>/6{' '}
                  {t('price_mounth3')} ( 1 {t('price_mounth1')} = 4$)
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>36</span>/12{' '}
                  {t('price_mounth3')} ( 1 {t('price_mounth1')} = 3$)
                </p>
              </div>
              <a
                rel='noreferrer'
                target='_blank'
                href='https://t.me/Expert_ProMaker_bot'
              >
                <button className='price_block_buy'>
                  {t('price_block_button')}
                </button>
              </a>
            </div>
            <div className='price_block'>
              <div className='price_circle'></div>
              <h2>{t('price_block_title2')}</h2>
              <div className='price_block_body'>
                <h3>{t('price_block_subtitle1')}</h3>
                <p>
                  <img src={priceDone} alt='' />
                  {t('2_price_block_desc1')}
                </p>
                <h3>{t('price_block_subtitle2')}</h3>
                <p>
                  <img src={priceDone} alt='' />
                  {t('2_price_block_desc2')}
                </p>
              </div>
              <div className='price_block_price'>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>8</span>/
                  {t('price_mounth1')}
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>21</span>/3{' '}
                  {t('price_mounth2')} ( 1 {t('price_mounth1')} = 7$)
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>36</span>/6{' '}
                  {t('price_mounth3')} ( 1 {t('price_mounth1')} = 6$)
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>60</span>/12{' '}
                  {t('price_mounth3')} ( 1 {t('price_mounth1')} = 5$)
                </p>
              </div>
              <a
                rel='noreferrer'
                target='_blank'
                href='https://t.me/Expert_ProMaker_bot'
              >
                <button className='price_block_buy'>
                  {t('price_block_button')}
                </button>
              </a>
            </div>
            <div className='price_block'>
              <div className='price_circle'></div>
              <h2>Premium</h2>
              <div className='price_block_body'>
                <h3>{t('price_block_subtitle1')}</h3>
                <p>
                  <img src={priceDone} alt='' />
                  {t('3_price_block_desc1')}
                </p>
                <h3>{t('price_block_subtitle2')}</h3>
                <p>
                  <img src={priceDone} alt='' />
                  {t('3_price_block_desc2')}
                </p>
              </div>
              <div className='price_block_price'>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>13</span>/
                  {t('price_mounth1')}
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>36</span>/3{' '}
                  {t('price_mounth2')} ( 1 {t('price_mounth1')} = 12$)
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>66</span>/6{' '}
                  {t('price_mounth3')} ( 1 {t('price_mounth1')} = 11$)
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>120</span>/12{' '}
                  {t('price_mounth3')} ( 1 {t('price_mounth1')} = 10$)
                </p>
              </div>
              <a
                rel='noreferrer'
                target='_blank'
                href='https://t.me/Expert_ProMaker_bot'
              >
                <button className='price_block_buy'>
                  {t('price_block_button')}
                </button>
              </a>
            </div>
            <div className='price_block'>
              <div className='price_circle'></div>
              <h2>Premium Pro</h2>
              <div className='price_block_body'>
                <h3>{t('price_block_subtitle1')}</h3>
                <p>
                  <img src={priceDone} alt='' />
                  {t('4_price_block_desc1')}
                </p>
                <h3>{t('price_block_subtitle2')}</h3>
                <p>
                  <img src={priceDone} alt='' />
                  {t('4_price_block_desc2')}
                </p>
              </div>
              <div className='price_block_price'>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>16</span>/
                  {t('price_mounth1')}
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>45</span>/3{' '}
                  {t('price_mounth2')} ( 1 {t('price_mounth1')} = 15$)
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>84</span>/6{' '}
                  {t('price_mounth3')} ( 1 {t('price_mounth1')} = 14$)
                </p>
                <p>
                  <span className='price_block_currency'>$</span>{' '}
                  <span className='price_block_number'>156</span>/12{' '}
                  {t('price_mounth3')} ( 1 {t('price_mounth1')} = 13$)
                </p>
              </div>
              <a
                rel='noreferrer'
                target='_blank'
                href='https://t.me/Expert_ProMaker_bot'
              >
                <button className='price_block_buy'>
                  {t('price_block_button')}
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <div id='blog' className='blog'>
        <div className='container'>
          <h2 className='blog_plot'>{t('blog_title')}</h2>
          <div id='blogId' className='blog_item_top'>
            <p>{t('blog1')}</p>
            <Link to='/dashboard?component=block1'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>

          <div className='blog_item_top'>
            <p>{t('blog4')}</p>
            <Link to='/dashboard?component=block4'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog5')}</p>
            <Link to='/dashboard?component=block5'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>

          <div className='blog_item_top'>
            <p>{t('blog2')}</p>
            <Link to='/dashboard?component=block2'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog3')}</p>
            <Link to='/dashboard?component=block3'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog6')}</p>
            <Link to='/dashboard?component=block6'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>

          <div className='blog_item_top'>
            <p>{t('blog7')}</p>
            <Link to='/dashboard?component=block7'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog8')}</p>
            <Link to='/dashboard?component=block8'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>

          <div className='blog_item_top'>
            <p>{t('blog9')}</p>
            <Link to='/dashboard?component=block9'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog10')}</p>
            <Link to='/dashboard?component=block10'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog11')}</p>
            <Link to='/dashboard?component=block11'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog12')}</p>
            <Link to='/dashboard?component=block12'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog13')}</p>
            <Link to='/dashboard?component=block13'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog14')}</p>
            <Link to='/dashboard?component=block14'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog15')}</p>
            <Link to='/dashboard?component=block15'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog16')}</p>
            <Link to='/dashboard?component=block16'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>

          <div className='blog_item_top'>
            <p>{t('blog17')}</p>
            <Link to='/dashboard?component=block17'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
          <div className='blog_item_top'>
            <p>{t('blog19')}</p>
            <Link to='/dashboard?component=block19'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>

          <div className='blog_item_top'>
            <p>{t('blog18')}</p>
            <Link to='/dashboard?component=block18'>
              <img src={blogOpen} alt='open_blog' />
            </Link>
          </div>
        </div>
      </div>
      <section id='response' className='response'>
        <div className='container'>
          <h2 className='features_plot'>{t('response')}</h2>
          <Swiper
            modules={[Navigation]}
            spaceBetween={50}
            slidesPerView={3}
            navigation
            onSwiper={swiper => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
            breakpoints={{
              100: {
                slidesPerView: 1
              },
              860: {
                slidesPerView: 2,
                spaceBetween: 0
              },
              1240: {
                slidesPerView: 3
              }
            }}
          >
            <SwiperSlide>
              <img className='response_block' src={response1} alt='' />
            </SwiperSlide>
            <SwiperSlide>
              <img className='response_block' src={response2} alt='' />
            </SwiperSlide>
            <SwiperSlide>
              <img className='response_block' src={response3} alt='' />
            </SwiperSlide>
            <SwiperSlide>
              <img className='response_block' src={response4} alt='' />
            </SwiperSlide>
            <SwiperSlide>
              <img className='response_block' src={response5} alt='' />
            </SwiperSlide>
          </Swiper>
          <div className='response_btn_item'>
            <a
              className='response_a'
              rel='noreferrer'
              target='_blank'
              href='https://t.me/Reviews_Expert/4'
            >
              <button className='response_btn'>{t('response_btn')}</button>
            </a>
          </div>
        </div>
      </section>
      <section id='contact' className='contact'>
        <div className='container'>
          <div className='contact_list'>
            <div className='contact_input_list'>
              <h2>{t('contact')}</h2>
              <div className='contact_input_item'>
                <input
                  value={inputName}
                  onChange={e => setInputName(e.target.value)}
                  placeholder={t('contact_placeholder1')}
                  type='text'
                />
                <hr />
              </div>
              <div className='contact_input_item'>
                <input
                  value={inputTelegram}
                  onChange={e => setInputTelegram(e.target.value)}
                  placeholder={t('contact_placeholder2')}
                  type='text'
                />
                <hr />
              </div>
              <div className='contact_input_item'>
                <input
                  value={inputEmail}
                  onChange={e => setInputEmail(e.target.value)}
                  placeholder={t('contact_placeholder3')}
                  type='text'
                />
                <hr />
              </div>
              <div className='contact_input_item'>
                <input
                  value={inputPhone}
                  onChange={e => setInputPhone(e.target.value)}
                  placeholder={t('contact_placeholder4')}
                  type='text'
                />
                <hr />
              </div>
              <button>{t('contact_button')}</button>
            </div>
            <img
              className='contact_logo'
              src={contactLogo}
              alt='contact-logo'
            />
          </div>
        </div>
      </section>
      <footer>
        <div className='container'>
          <div className='nav_body nav_footer'>
            <img className='nav_logo' src={logo} alt='' />
            <div className='nav_links'>
              <a className='nav_link' href='#main'>
                {t('nav1')}
              </a>
              <a className='nav_link' href='#about'>
                {t('nav2')}
              </a>
              <a className='nav_link' href='#functional'>
                {t('nav3')}
              </a>
              <a className='nav_link' href='#price'>
                {t('nav4')}
              </a>
              <a
                className='nav_link'
                rel='noreferrer'
                target='_blank'
                href='https://t.me/Expert_bot_chat'
              >
                {t('nav5')}
              </a>
              <a className='nav_link' href='#blog'>
                {t('nav7')}
              </a>
              <a className='nav_link' href='#response'>
                {t('nav8')}
              </a>
            </div>
            <div className='nav_buttons'>
              <div className='switch_language'>
                <p onClick={() => changeLanguage('ru')}>RU</p>
                <span>/</span>
                <p onClick={() => changeLanguage('en')}>EN</p>
                <span>/</span>
                <p>UA</p>
              </div>
              <a
                rel='noreferrer'
                target='_blank'
                href='https://t.me/Expert_ProMaker_bot'
              >
                <button className='open_bot'>Expert Bot</button>
              </a>
            </div>
          </div>
          <hr />
          <p className='copyright'>© 2023 Expert Pro </p>
          <div className='policy'>
            <a href='#123'>Terms of use</a>|<a href='#123'>Privacy Policy</a>
          </div>
        </div>
      </footer>
    </section>
  )
}
export default Main
