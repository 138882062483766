import '../App.css';
import video from '../video/video16.mp4';

function Block16(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog16-15")}</h2>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-1")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-2")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-3")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-4")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-5")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-6")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-7")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-8")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-9")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-10")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-11")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-12")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-13")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog16-14")}</p>
            </div>
        </div>

    )
}
export default Block16;