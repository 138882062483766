import '../App.css';
import video from '../video/video12.mp4';

function Block12(props) {

    return (
        <div className='active_block'>
            <div className='container container_block'>
                <video className='video' controls src={video}></video>
                <h2 style={{ marginTop: '50px', marginBottom: '30px' }}>{props.t("blog12-7")}</h2>
                <p style={{ marginTop: '5px' }}>{props.t("blog12-1")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog12-2")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog12-3")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog12-4")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog12-5")}</p>
                <p style={{ marginTop: '5px' }}>{props.t("blog12-6")}</p>

            </div>
        </div>

    )
}
export default Block12;